:root {
    --color-red: #f4081f;
    --color-red-10: #da071b;
    --color-maroon: #452325;

    --color-white: #ffffff;
    --color-grey: #eaeaea;
    --color-grey-very-light: #f8f8f8;
    --color-grey-light: #f4f4f4;
    --color-grey-tint: #d2d2d2;
    --color-grey-shade: #aaaaaa;
    --color-grey-medium: #959595;
    --color-grey-dark: #545454;
    --color-grey-deep: #333333;

    --color-star-buy-solid: #E47600;
    --color-star: #F7BD02;

    --color-blue: #047fd1;
    
    /* Gradients */
    /* https://uigradients.com/#BurningOrange */
    --color-star-buy-gradient: linear-gradient(90deg, #E47600, #F7BD02);
    --color-promo-gradient: linear-gradient(to right, #ff416c, #ff4b2b);

}